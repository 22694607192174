<template>
    <div class="loader-wrap">
        <p>{{message}}</p>
        <div class="loader"><div></div></div>
    </div>
</template>

<script>
import '@/sass/components/global/_loader.scss';
export default {
    name: "Loader",
    props: ['message']
}
</script>