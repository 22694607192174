<template>
    <header id="app-header" :class="version">
        <nav>
            <router-link to="/cart" aria-label="Cart" class="text-decoration-none">
                <div class="d-flex align-items-center">
                    <svg width="39" class="flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.2 38.46">
                        <path
                            d="M12.31,30.77a3.85,3.85,0,1,0,3.84,3.84A3.84,3.84,0,0,0,12.31,30.77ZM0,0V3.84H3.84l6.92,14.58L8.17,23.13a3.82,3.82,0,0,0,3.46,5.69H34.58V25H12.33a.48.48,0,0,1-.48-.48l.06-.23,1.73-3.14H28.73a3.84,3.84,0,0,0,3.37-2L39,6.69a1.83,1.83,0,0,0,.23-.93,1.93,1.93,0,0,0-1.92-1.92H8.09L6.28,0ZM31.52,30.77a3.85,3.85,0,1,0,3.84,3.84A3.84,3.84,0,0,0,31.52,30.77Z"
                            fill="#ffe885"
                        />
                    </svg>
                    <div v-if="holdToken" class="ml-4">
                        <span class="ml-4" style="color: black; padding-left: 1em">{{ timer }}</span>
                    </div>
                </div>
            </router-link>
            <img class="brand" src="@/assets/i2tickets.svg" alt="sLocal Tix" />
            <button class="menu-trigger" @click="openMenu()" :class="{ hide: isOpen }">
                <fa icon="bars" />
            </button>
            <button class="back c2-btn" @click="$router.back()">
                <fa icon="arrow-left" />
            </button>
            <button class="dash c2-btn" @click="$router.back('/')">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43.23 43.23">
                    <path d="M0,24H19.21V0H0ZM0,43.23H19.21V28.82H0Zm24,0H43.23v-24H24ZM24,0V14.41H43.23V0Z" />
                </svg>
            </button>
        </nav>
        <h1 ref="title">{{ title }}</h1>
    </header>
    <MainMenu :isOpen="isOpen" @closeMenu="closeMenu" />
    <main ref="main">
        <svg viewbox="0 0 100 100" preserveAspectRatio="xMidYMin slice">
            <defs>
                <filter id="filterGrayscale" filterUnits="userSpaceOnUse">
                    <feColorMatrix in="SourceGraphic" type="saturate" values="0.10" />
                </filter>
                <clipPath v-if="hbox" id="cutout">
                    <path :d="pData" />
                </clipPath>
            </defs>
        </svg>
        <Loader v-if="$route.meta.authRequired && !$store.state.isAuthed" class="main-content" message="looking up account..." />
        <slot v-else />
    </main>
    <nav id="app-nav">
        <router-link :to="{ name: 'Tickets' }">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54.16 43.23">
                <path d="M43.31,21.61A12.67,12.67,0,0,1,54.16,9.07V0H0V43.23H54.16V34.16A12.68,12.68,0,0,1,43.31,21.61Z" />
            </svg>
            <span>Tickets</span>
        </router-link>
        <router-link :to="{ name: 'Home' }">
            <fa icon="home" />
            <span>Home</span>
        </router-link>
    </nav>
    <footer id="app-footer">
        <strong>Having Trouble? </strong>
        <span>Contact our help desk by</span>
        Email: <a href="mailto:support@i2tickets.com.com">support@i2tickets.com</a>
    </footer>
    <div id="modalBox"></div>
</template>

<script>
    // styles
    import "@/sass/global/_wrapper.scss";
    // components
    import MainMenu from "@/components/UI/MainMenu.vue";
    export default {
        name: "layoutFull",
        props: ["title", "version"],
        components: { MainMenu },
        data() {
            return {
                hbox: null,
                cbox: null,
                isOpen: false,
                timer: "--:--",
                isTimerActive: true,
            };
        },
        watch: {
            title: {
                handler(n, o) {
                    if (n != o) {
                        this.calcSize();
                    }
                },
                flush: "post",
            },
        },
        computed: {
            bw() {
                return this.cbox.width;
            },
            bh() {
                return this.cbox.height + 100;
            },
            hh() {
                return this.hbox.height / 2;
            },
            offset() {
                return (this.cbox.width - (this.hh + this.hbox.width)) / 2;
            },
            hw() {
                return this.hbox.width - this.hh;
            },
            pData() {
                return `M0,0 H${this.offset} a ${this.hh} ${this.hh} 0 0 0 ${this.hh},${this.hh} h${this.hw} a ${this.hh} ${this.hh} 0 0 0 ${this.hh},-${this.hh} H${this.bw} V${this.bh} H0`;
            },
            cart() {
                return this.$store.state.cart;
            },
            holdToken() {
                if (!this.$store.state.cart) return false;
                if (!this.$store.state.cart.holdToken) return false;
                return this.$store.state.cart.holdToken;
            },
        },
        methods: {
            closeMenu() {
                this.isOpen = false;
            },
            openMenu() {
                this.isOpen = true;
            },
            calcSize() {
                this.hbox = this.$refs.title.getBoundingClientRect();
                this.cbox = this.$refs.main.getBoundingClientRect();
            },
            setTimer() {
                let frame = 60;
                const recur = () => {
                    if (frame === 0) {
                        this.timer = this.getFormattedCounter();
                        frame = 60;
                    }
                    window.requestAnimationFrame(() => {
                        frame--;
                        recur();
                    });
                };
                recur();
            },
            getFormattedCounter() {
                if (!this.holdToken) return "";
                const milliseconds = Date.parse(this.holdToken.expiresAt) - Date.now();
                if (milliseconds <= 0) {
                    return "0:00";
                }
                const totalSeconds = Math.floor(milliseconds / 1000);
                const minutes = Math.floor(totalSeconds / 60);
                const seconds = totalSeconds % 60;
                return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
            },
        },
        mounted() {
            this.calcSize();
            window.addEventListener("resize", this.calcSize);
            this.setTimer();
        },
        beforeUnmount() {
            this.isTimerActive = false;
        },
    };
</script>
