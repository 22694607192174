<template>
    <aside id="main-menu" :class="{ 'open': isOpen }">
        <button class="close" @click="closeMenu()"><fa icon="times" /></button>
        <ul>
            <li><router-link :to="{name: 'Tickets'}" @click="closeMenu()">My Tickets</router-link></li>
            <li><router-link :to="{name: 'My Venues'}" @click="closeMenu()">My Venues</router-link></li>
            <li><router-link :to="{name: 'Cart'}" @click="closeMenu()">My Cart</router-link></li>
            <li class="logout"> <button @click="logout()"> <fa icon="sign-out-alt" /> Logout</button></li>
            <li class="label">{{userEmail}}</li>
        </ul>
    </aside>

</template>

<script>
import '@/sass/components/global/_main-menu.scss';
export default {
    name: "MainMenu",
    props: ['isOpen'],
    computed: {
        userEmail(){
            return this.$store.state.user && (this.$store.state.user.email || 'loading email');
        },
    },
    methods:{
        closeMenu(){
            this.$emit('closeMenu');
        },
        logout() {
            this.closeMenu();
            this.$auth0.logout({returnTo: this.$store.state.logoutUrl});
        },
    }
}
</script>