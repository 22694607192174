import { createStore } from 'vuex';
import axios from 'axios';
import bwipjs from 'bwip-js';
const cap = {
    b: (p, s) => p + s.charAt(0).toUpperCase() + s.slice(1),
    set: function (s) { return this.b('set', s) },
    get: function (s) { return this.b('get', s) },
    fetch: function (s) { return this.b('fetch', s) },
}
// const isDev = !process.env.VUE_APP_PROD_API;
const baseUrl = window.location.href.split('/').slice(0, 3).join('/')
const groups = false;
//create store
const store = createStore({
    state() {
        return {
            appStatus: 'Getting Ready',
            isAuthed: false,
            hasToken: false,
            user: null,
            authClientId: process.env.VUE_APP_AUTH_ClientId,
            authUrl: process.env.VUE_APP_AUTH_URL,
            apiUrl: process.env.VUE_APP_API_URL,
            apiEndpoint: process.env.VUE_APP_API_Endpoint,
            apiAudience: process.env.VUE_APP_AUTH_Audience,
            clientUrl: baseUrl,
            imgUrl: process.env.VUE_APP_AUTH_ImageUrl,
            passUrl: process.env.VUE_APP_AUTH_PassUrl,
            logoutUrl: process.env.VUE_APP_AUTH_LogoutUrl,
            venues: false,
            useEventGroups: baseUrl.includes('2d') || groups,
            venueEndpointOverride: (baseUrl.includes('2d') || groups) ? 'venues/2d' : false
        }
    },
    actions: {
        //Get Requests
        async initData({ commit, state }, obj) {
            console.log('STEP 4');
            commit('setAuthed', true);
            commit('setToken', obj.token);
            commit('setUser', obj.user);
        },
        async fetchVenues({ commit, state, dispatch }) {
            console.log('fetching venues');
            let endpoint = state.venueEndpointOverride || 'venues/me';
            let vens = await axios(endpoint);
            vens.data.forEach(v => {
                v.imgPath = `${state.imgUrl + v.guid}/SiteLogo.png`;
                // v.imgBlob = false;
            });
            commit('setVenues', vens.data);
            // state.venues.forEach(v => dispatch('fetchLogoBlob', v.guid));
            return vens.data;
        },
        async fetchCart({ commit }) {
            let cart = await axios.get('carts');
            let cartData = cart.data || { cartItems: [] };
            commit('setCart', cartData);
            return cart.data;
        },
        async fetchCartCustomFieldValues() {
            const customFieldValues = await axios.get('carts/items/custom-field-values');
            return customFieldValues.data;
        },
        async fetchVenProp({ commit, getters }, obj) {
            let propType = {
                events: 'events',
                eventgroups: 'eventgroups',
                tickets: 'eventtickets',
                passes: 'seasonpasses',
                locations: 'locations',
                event: 'event',
                location: 'location',
            }
            let res, path = `venues/${obj.id}/${propType[obj.type]}`;
            res = await axios(path);
            if (obj.pid) {
                path += '/' + obj.pid;
                return res;
            }
            let payload = {
                i: getters.getVenIndex(obj.id),
                data: res.data
            }
            commit(cap.set(obj.type), payload)
            return res;
        },
        async fetchLogoBlob({ commit, getters }, id) {
            let ven = getters.getVenue(id);
            fetch(ven.imgPath)
                .then(r => {
                    if (!r.ok) { return false; }
                    return r.blob();
                })
                .then(img => {
                    let url = img == false ? false : URL.createObjectURL(img);
                    commit('setVenueLogoBlob', { id, blob: url })
                })
        },
        // "require" actions check if given data exists.
        //  if no data, dispatches a fetch action
        requireVenData({ dispatch, getters }, dataType) {
            let check = getters[cap.get(dataType.type)].call(getters, dataType.id);
            if (check != null) { return true } // has data; return it to caller
            dispatch('fetchVenProp', { id: dataType.id, type: dataType.type });
            return false; //no data, but fetching in background...
        },
        requireData({ dispatch, getters }, dataType) {
            let check = getters[cap.get(dataType)];
            if (!!check) { return true }
            dispatch(cap.fetch(dataType));
            return false;
        },
        async fetchVenueSearchResults(c, term) {
            let search = await axios('venues/search/' + term);
            return search.data;
        },
        async fetchEventsSearchResults(c, term) {
            let search = await axios(`search/events/${term}/false`);
            return search.data;
        },
        async fetchPurchaseUrl(c) {
            let res = await axios.post('carts/checkout/1')
            return res.data;
        },
        async fetchUserPurchases({ commit }) {
            let res = await axios('passes');
            commit('setUserPurchases', res.data.passes);
        },
        async generateCode({ commit }, p) {
            let barcode = p.eventData.id == 0 ? 'SP-' : 'EVT-';
            barcode += (p.productData.id + '-');
            barcode += p.productData.venueGUID.split('-')[1] + '-';
            barcode += p.serialNumber.split('-')[1];
            let canvas = document.createElement('canvas');
            let obj = { sn: p.serialNumber };
            try {
                bwipjs.toCanvas(canvas, {
                    bcid: 'azteccode',
                    text: barcode,
                    scale: 7
                })
            } catch (e) { sn = 'error'; }
            obj.barcode = canvas.toDataURL();
            commit('setPassCode', obj);
        },
        //Post Requests
        async updateUserVenues({ dispatch }, p) {
            let list = [];
            p.deletes.forEach(id => {
                list.push(axios.delete('users/venues/' + id))
            });
            p.adds.forEach(id => {
                list.push(axios.post('users/venues/' + id))
            });
            await axios.all(list);
            dispatch('fetchVenues');
        },
        async updateCartAdd({ commit }, item) {
            let res = await axios.post('carts/items', item)
            commit('setCart', res.data)
            return res;
        },
        async updateCartDelete({ dispatch }, id) {
            let res = await axios.delete('carts/items/' + id)
            dispatch('fetchCart');
            return res.data;
        },
        async textTicket(c, data) {
            let res = await axios.post('/passes/text', data)
            return res.data;
        }
    },
    mutations: {
        // setRuri(state,url){
        //     state.ruri = url;
        // },
        setAppStatus(state, status) {
            state.appStatus = status;
        },
        setAuthed(state, is) {
            state.isAuthed = is;
        },
        setToken(state, token) {
            state.hasToken = token;
        },
        setUser(state, user) {
            state.user = user;
        },
        setVenues(state, venues) {
            state.venues = venues;
        },
        setVenueLogoBlob(state, obj) {
            state.venues.find(v => v.guid == obj.id).imgBlob = obj.blob;
        },
        setEvents(state, obj) {
            state.venues[obj.i].events = obj.data;
        },
        setEventgroups(state, obj) {
            state.venues[obj.i].eventgroups = obj.data;
        },
        setPasses(state, obj) {
            state.venues[obj.i].passes = obj.data;
        },
        setPassCode(state, obj) {
            state.purchases.find(p => p.serialNumber == obj.sn).barcode = obj.barcode;
        },
        setTickets(state, obj) {
            state.venues[obj.i].tickets = obj.data;
        },
        setLocations(state, obj) {
            state.venues[obj.i].locations = obj.data;
        },
        setCart(state, data) {
            state.cart = data || [];
        },
        setUserPurchases(state, arr) {
            state.purchases = arr;
        }
    },
    getters: {
        //uber lists
        getVenues: state => state.venues,
        getCart: state => state.cart,
        getUserPurchases: state => state.purchases,
        //single item 
        getVenue: state => id => state.venues.find(v => v.guid == id),
        getVenueByIDs: state => id => state.venues.find(v => v.guid.includes(id)),
        getVenIndex: state => id => state.venues.findIndex(v => v.guid == id),
        // venue lists
        getEvents: (s, getters) => id => getters.getVenue(id).events,
        getEventgroups: (s, getters) => id => getters.getVenue(id).eventgroups,
        getPasses: (s, getters) => id => getters.getVenue(id).passes,
        getTickets: (s, getters) => id => getters.getVenue(id).tickets,
        getLocations: (s, getters) => id => getters.getVenue(id).locations,
        // venue list item in list
        getEventInEvents: () => (events, eid) => events.find(e => e.id == eid),
        getEventgroupInEventgroups: () => (egs, egid) => egs.find(eg => eg.id == egid),
        getPassInPasses: () => (passes, pid) => passes.find(p => p.productId == pid),
        getLocationInLocations: () => (locations, lid) => locations.find(l => l.id == lid),
        // venue list item without list
        getEvent: (s, getters) => (id, eid) => getters.getEvents(id).find(e => e.id == eid),
        getPass: (s, getters) => (id, pid) => getters.getPasses(id).find(p => p.productId == pid),
        getLocation: (s, getters) => (id, eid) => getters.getLocations(id).find(e => e.id == eid),
        getTicket: (s, getters) => (id, pid) => getters.getTickets(id).find(t => t.productId == pid),
    }
});
export default store;
