<template>
    <component :is="layout" :version="version" :title="title">
        <router-view v-slot="{Component}" >
            <transition :name="transitionName">
                <component :is="Component" />
            </transition>
        </router-view>
    </component>
</template>

<script> 


import layout from '@/components/UI/Full.vue';
export default {
    data(){
        return{
            transitionName: null,
            user: this.$auth0.user,
            authed: this.$auth0.isAuthenticated,
            loading: this.$auth0.isLoading,
            hiJackNextNav: false
        }
    },
    computed:{
        layout(){
            return layout;
        },
        title(){
            let hasPageName = this.$route.meta && this.$route.meta.pageName;
            return hasPageName ? this.$route.meta.pageName : this.$route.name || this.$store.state.appStatus
        },
        version(){
            return this.$route.meta.layout;
        },
    },
    watch: {
        $route (to, from) {
            this.transitionName = to.path == '/' ? 'slide-right' : 'slide-left';
        },
    },
    methods: {
        updateTitle(txt){
            this.title = txt;
        },
        async getToken(){
            if (!this.authed) {
                // if not authed after loading is compelete, login redirect
                localStorage.setItem('lastPath', window.location.pathname);
                this.$auth0.loginWithRedirect({ redirect_uri: window.location.origin, skipRedirectCallback: true });
                return false;
            }
            console.log(this.authed + ':  already authed');
            let token = await this.$auth0.getAccessTokenSilently();
            this.$store.dispatch('initData', {user: this.user,token});
            this.$axios.init();
            return true;
        },
        async checkRoute(to, from) {
            console.log(to.path, from.path, window.location.href);
            if(this.hiJackNextNav){
                let lp = localStorage.getItem('lastPath') || to.path;
                localStorage.removeItem('lastPath');
                this.hiJackNextNav = false;
                return lp;
            }
            if(to.query.hasOwnProperty('code')){
                this.hiJackNextNav = true;
                return false;
            }
            if(!to.meta.authRequired) { return true; } // auth not required
            if(!!this.$store.state.hasToken) { return true } // protect route nav
            if(!this.loading){ return await this.getToken(); } // loads auth token if possibe, or redirects
            
            this.$watch('loading',this.getToken);
        }
    },
    created(){
        this.$router.beforeEach(this.checkRoute);

    },
    mounted(){
        const height = window.innerHeight;
        document.documentElement.style.setProperty("--vp-height", window.innerHeight + 'px');
        window.addEventListener('resize', ()=>{
            window.scrollTo(0,0);
            document.documentElement.style.setProperty("--vp-height", window.innerHeight + 'px');    
        });
    }
}
</script>
