const toggle = (el, binding) => {
    // vars
    const target = binding.arg ? document.querySelector(binding) : el.nextElementSibling;
    const resizeOb = new ResizeObserver((e)=>{calcSize(target)});
    // functions
    function calcSize(){
        target.style.setProperty('--to-height', target.scrollHeight + 4 + "px");
        target.setAttribute('aria-expanded', false);
    }
    function toggleOpen(){
        let open = this.classList.toggle('open');
        target.setAttribute('aria-expanded', open);
    }
    // init
    el.addEventListener('click', toggleOpen);
    resizeOb.observe(target.firstElementChild);
}
export default {mounted: toggle};