import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
const eventRoute = store.state.useEventGroups ? {
    path: '/:venIDs/event-:id',
    name: 'EventGroup',
    props: true,
    meta: {
        layout: 'full',
        authRequired: true
    },
    component: () => import(/* webpackChunkName: "eventgroup" */ '../views/EventGroup.vue'),
} : {
    path: '/:venIDs/event-:id',
    name: 'Event',
    props: true,
    meta: {
        layout: 'full',
        authRequired: true
    },
    component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue'),
};
const routes = [
    eventRoute,
    {
        path: '/',
        name: 'Home',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    },
    {
        path: '/search',
        name: 'Search',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
    },
    {
        path: '/tickets',
        name: 'Tickets',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "tickets" */ '../views/Tickets.vue'),
    },
    {
        path: '/cart',
        name: 'Cart',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "cart" */ '../views/Cart.vue'),
    },
    {
        path: '/checkout',
        name: 'Checkout',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "checkout" */ '../views/Checkout.vue'),
    },
    {
        path: '/options',
        name: 'My Venues',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "options" */ '../views/Options.vue'),
    },
    {
        path: '/:venIDs/passes',
        name: 'SeasonPasses',
        props: true,
        meta: {
            layout: 'full',
            pageName: 'Season Passes',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "seasonPasses" */ '../views/SeasonPasses.vue'),
    },
    {
        path: '/:venIDs/pass-:id',
        name: 'SeasonPass',
        props: true,
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "seasonPass" */ '../views/SeasonPass.vue'),
    },
    {
        path: '/:venIDs',
        name: 'Venue',
        props: true,
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "venue" */ '../views/Venue.vue'),
    },
    {
        path: '/authcallback',
        name: 'AuthCallback',
        meta: {
            layout: 'full',
            authRequired: true
        },
        component: () => import(/* webpackChunkName: "authCallback" */ '../views/AuthCallback.vue'),
    },
    {
        path: '/confirmation',
        name: 'Confirmation',
        meta: {
            layout: 'full',
            authRequired: false
        },
        component: () => import(/* webpackChunkName: "confirmation" */ '../views/Confirmation.vue'),
    },
    {
        path: '/eventtest',
        name: 'EventTest',
        meta: {
            layout: 'full',
            authRequired: false
        },
        component: () => import(/* webpackChunkName: "confirmation" */ '../views/Eventtest.vue'),
    },
    {
        path: '/test',
        name: 'Test',
        meta: {
            layout: 'full',
            authRequired: false
        },
        component: () => import(/* webpackChunkName: "confirmation" */ '../views/Test.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
export default router