import axios from 'axios';
export default {
    install: async (app) => {
        const store = app.config.globalProperties.$store;
        const auth = app.config.globalProperties.$auth0;

        const plain = axios.create();
        const init = async () => {
            axios.defaults.baseURL = store.state.apiUrl + store.state.apiEndpoint;
            axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.hasToken}`;
            axios.interceptors.response.use(res => res, dataErrorHandler);
        }
        const dataErrorHandler = async (err) => {
            console.log(err);
            if (!err.response) return Promise.reject(err);
            let retry, reload;
            switch (err.response.status) {
                case 401:
                    retry = window.confirm('You appear to be logged out. Try auto-login?')
                    if (!retry) { return Promise.reject(err) } // prevents loop
                    console.log('invalid user; attempting relogin');
                    auth.logout({ returnTo: window.location.origin });
                    break;
                case 404:
                    reload = window.confirm('ERROR: fetching data for: \n' + err.request.responseURL + ' \n\nReload Page?')
                    if (!reload) { return Promise.reject(err) } // prevents loop
                    window.location.reload();
            }
            return Promise.reject(err)
        }
        const setAuthToken = async token => {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
        app.config.globalProperties.$axios = {
            init,
            setAuthToken,
            plain,
        };
    }
}
