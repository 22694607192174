import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ax from './plugins/Axios';
// import sentry from './plugins/Sentry';
import VueGtag from "vue-gtag-next";

// auth0
import { createAuth0 } from '@auth0/auth0-vue';
const auth = createAuth0({
    domain: store.state.authUrl,
    clientId: store.state.authClientId,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: store.state.apiAudience
    }
});

//font awesome
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
library.add(fas, fab);

//styles
import "../node_modules/bootstrap/scss/bootstrap-reboot.scss";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import '@/sass/global/_core.scss';
import '@/sass/global/_utility.scss';
import '@/sass/global/_transitions.scss';
//components
import '@/sass/components/global/_forms.scss';
import '@/sass/components/global/_buttons.scss';
import '@/sass/components/global/_modal.scss';
//modules
import '@/sass/views/_landing.scss';
import '@/sass/views/_settings.scss';
import '@/sass/views/_passes.scss';
import '@/sass/views/_events.scss';
import '@/sass/views/_search.scss';
import '@/sass/views/_tickets.scss';

//custom global components
import Loader from '@/components/UI/Loader.vue';

// custom global directives
import Toggle from '@/directives/toggle.js';

export default createApp(App)
    .use(store)
    .use(router)
    .use(VueGtag, { config: { id: "G-QZD1STH1JC" } }, router)
    .use(auth)
    .use(ax)
    // .use(sentry)
    .component('fa', FontAwesomeIcon)
    .component('Loader', Loader)
    .directive('toggle', Toggle)
    .mount('#app');
